// @ts-nocheck

import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { usersApi } from "../services/user.ts";
import { toast } from "react-toastify";
import axiosClient from "../utils/axios.tsx";

const HANDLERS = {
  INITIALIZE: "INITIALIZE",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  UPDATE_USER: "UPDATE_USER",
};

const initialState: {
  user: null | User;
  isAuthenticated: boolean;
  isLoading: boolean;
} = {
  isAuthenticated: Cookies.get("x-employee-auth-token") ? true : false,
  isLoading: true,
  user: null,
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      ...(user
        ? {
            isAuthenticated: true,
            isLoading: false,
            user,
          }
        : {
            isLoading: false,
            isAuthenticated: user ? true : null,
          }),
    };
  },
  [HANDLERS.SIGN_IN]: (state, action) => {
    const user = action.payload;
    return {
      ...state,
      isAuthenticated: user !== null ? true : false,
      user,
    };
  },
  [HANDLERS.SIGN_OUT]: (state) => ({ ...state, isAuthenticated: false }),
  [HANDLERS.UPDATE_USER]: (state, action) => {
    return {
      ...state,
      user: action.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  signIn: (email, password) => Promise.resolve(),
  signOut: () => Promise.resolve(),
  updateUser: (userData) => {},
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(
    Cookies.get("x-employee-auth-token") || "",
  );

  const signIn = async (email, password) => {
    try {
      const user = await usersApi.loginUser({ email, password });
      if (user?.token) {
        if (user?.role === "EMPLOYEE") {
          toast.success("Successfully logged in");
          Cookies.set("x-employee-auth-token", user?.token);
          Cookies.set("employee-user-id", user?.user_id);
          setToken(user?.token);
          navigate("/dashboard");
          dispatch({ type: HANDLERS.SIGN_IN, payload: user.user });
          axiosClient()
            .patch("users/last-login", {
              email: user?.email,
              token: user?.token,
            })
            .catch((err) => console.error(err));
        } else if (user?.role === "ADMIN" || user?.role === "AFFILIATES") {
          toast.error("These credentials are not compatible with this panel");
        }
      }
    } catch (err) {
      if (err.response?.data?.message === "Account removed") {
        toast.error("Your account has been removed. Please contact support.");
      } else {
        console.error(err);
        toast.error(err.response?.data?.message || "An error occurred");
      }
    }
  };

  const initialize = async () => {
    try {
      if (token) {
        const user = await usersApi.getUserByToken();
        if (user) {
          dispatch({
            type: HANDLERS.INITIALIZE,
            payload: user,
          });
          return; // Exit if the user is successfully fetched
        }
      }

      // Only remove cookies and redirect if it is a protected route
      const publicRoutes = [
        "/",
        "/signin",
        "/sign-up",
        "/verify-email",
        "/forget-password",
        "/check-email",
        "/new-password",
        "/successfully-reset",
      ];
      if (!publicRoutes.includes(location.pathname)) {
        Cookies.remove("x-employee-auth-token");
        Cookies.remove("employee-user-id");
        navigate("/");
      }

      dispatch({
        type: HANDLERS.INITIALIZE,
        payload: null,
      });
    } catch (err) {
      // Only remove cookies and redirect if it is a protected route
      const publicRoutes = [
        "/",
        "/signin",
        "/sign-up",
        "/verify-email",
        "/forget-password",
        "/check-email",
        "/new-password",
        "/successfully-reset",
      ];
      if (!publicRoutes.includes(location.pathname)) {
        Cookies.remove("x-employee-auth-token");
        Cookies.remove("employee-user-id");
        navigate("/");
      }

      console.error("Error fetching user: ", err);
      dispatch({
        type: HANDLERS.INITIALIZE,
        payload: null,
      });
    }
  };

  const updateUser = (userData) => {
    dispatch({ type: HANDLERS.UPDATE_USER, payload: userData });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const signOut = () => {
    Cookies.remove("x-employee-auth-token");
    Cookies.remove("employee-user-id");
    dispatch({ type: HANDLERS.SIGN_OUT });
  };

  return (
    <AuthContext.Provider value={{ ...state, signIn, signOut, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
export const useAuthContext = () => useContext(AuthContext);
