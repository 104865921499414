import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart: React.FC<{ stats: any }> = ({ stats }) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "monotoneCubic",
      width: 2,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontFamily: "Inter, sans-serif", // Change this to your preferred font
          fontSize: "12px", // Change this to your preferred font size
          colors: "#475467", // Change this to your preferred color
          fontWeight: "400",
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      tickAmount: 8,
    },
    grid: {
      borderColor: "#F2F4F7", // Set grid line color
      strokeDashArray: 0, // Set to 0 for solid lines
      opacity: 0.5, // Reduce opacity for the grid lines
    },
    colors: ["#155EEF"],
    legend: {
      show: true,
      position: "top",
    },
    fill: {
      gradient: {
        shadeIntensity: 0.1,
        type: "vertical",
        shade: "light",
        opacityFrom: 0.3,
        opacityTo: 0.05,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: "#7F56D9",
            opacity: 0.3,
          },
          {
            offset: 5,
            color: "#7F56D9",
            opacity: 0.05,
          },
        ],
      },
    },
  };

  const series = [
    {
      name: "Total $",
      data: stats?.result?.map((item) => item?.total) || [],
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default LineChart;
