import React, { useCallback, useEffect, useState } from "react";
import { Input, Typography, Button, Spin, Modal } from "antd";
import { useDropzone } from "react-dropzone";
import { useAuthContext } from "../../context/auth-context.tsx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axiosClient from "../../utils/axios.tsx";
import { toast } from "react-toastify";
import Cropper, { Area } from "react-easy-crop";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user, updateUser } = useAuthContext();
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [isCropModalVisible, setIsCropModalVisible] = useState(false);

  const navigate = useNavigate();

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        // Extract only the base64 data part
        const base64Data = base64String.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = () =>
        reject(new Error("Failed to convert blob to base64"));
      reader.readAsDataURL(blob);
    });
  };

  const compressImage = async (
    blob: Blob,
    maxSizeKB: number = 50,
  ): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(blob);

      image.onload = () => {
        const canvas = document.createElement("canvas");
        let width = image.width;
        let height = image.height;

        // Maintain aspect ratio while reducing size
        const maxDimension = 800;
        if (width > height && width > maxDimension) {
          height = (height * maxDimension) / width;
          width = maxDimension;
        } else if (height > maxDimension) {
          width = (width * maxDimension) / height;
          height = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("Failed to get canvas context"));
          return;
        }

        ctx.drawImage(image, 0, 0, width, height);

        // Start with high quality
        let quality = 0.9;
        const tryCompress = () => {
          canvas.toBlob(
            (compressedBlob) => {
              if (!compressedBlob) {
                reject(new Error("Failed to compress image"));
                return;
              }

              if (compressedBlob.size / 1024 > maxSizeKB && quality > 0.1) {
                quality -= 0.1;
                tryCompress();
              } else {
                resolve(compressedBlob);
              }
            },
            "image/jpeg",
            quality,
          );
        };

        tryCompress();
      };

      image.onerror = () => reject(new Error("Failed to load image"));
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result as string);
        setFormEdited(true);
        setIsCropModalVisible(true);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
    },
  });

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
  });

  useEffect(() => {
    setImage(user?.profile_image || null);
  }, [user]);

  const getCroppedImg = async (
    imageSrc: string,
    croppedAreaPixels: Area,
  ): Promise<{ url: string; base64: string }> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = async () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          reject(new Error("Failed to get canvas context"));
          return;
        }

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
        );

        canvas.toBlob(async (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }

          try {
            const compressedBlob = await compressImage(blob);
            const base64Data = await blobToBase64(compressedBlob);
            const fileUrl = URL.createObjectURL(compressedBlob);
            resolve({ url: fileUrl, base64: base64Data });
          } catch (error) {
            reject(error);
          }
        }, "image/jpeg");
      };
      image.onerror = reject;
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name: user?.name || "",
        profile_image: user?.profile_image,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setIsLoading(true);
        try {
          const payload: { name: string; profile_image?: string } = {
            name: values.first_name,
          };

          if (values.profile_image != null) {
            payload.profile_image = values.profile_image;
          }

          const res = await axiosClient().patch(
            `/users/${user?.user_id}`,
            payload,
          );
          toast.success("Profile updated successfully");
          setIsLoading(false);
          setFormEdited(false);
          updateUser({
            ...user,
            name: res?.data?.name,
            profile_image: res?.data?.profile_image || null,
          });
        } catch (err: any) {
          toast.error(
            err?.response?.data?.message || "Failed to update profile",
          );
          setIsLoading(false);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue }) => {
        const handleCropComplete = async () => {
          if (croppedArea && image) {
            try {
              const { url, base64 } = await getCroppedImg(image, croppedArea);
              setCroppedImage(url);
              setFieldValue(
                "profile_image",
                "data:image/jpeg;base64," + base64,
              );
              setIsCropModalVisible(false);
              setFormEdited(true);
            } catch (error) {
              console.error("Error cropping image: ", error);
              toast.error("Failed to crop image");
            }
          }
        };

        return (
          <Form>
            <div>
              <div className="flex items-center gap-6 firstForm">
                <div className="w-full">
                  <h6 className="text-[#344054] text-[14px] font-medium">
                    Name
                  </h6>
                  <Field
                    type="text"
                    name="first_name"
                    as={Input}
                    placeholder=""
                    onChange={(event) => {
                      setFieldValue("first_name", event.target.value);
                      setFormEdited(true);
                    }}
                    className="w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-2"
                  />
                  <div className="h-[10px]">
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full mt-4 secondForm">
                <h6 className="text-[#344054] text-[14px] font-medium">
                  Email address
                </h6>
                <Input
                  style={{
                    boxShadow: "0px 1px 2px 0px #1018280D",
                  }}
                  placeholder=""
                  value={user?.email}
                  disabled
                  className="w-full h-[44px] placeholder:text-[#667085] text-[#26292e] cursor-not-allowed rounded-[8px] mt-2 bg-[#F9FAFB]"
                />
              </div>

              <div className="flex items-start gap-5 mt-6 thirdForm">
                <div className="h-[125px] w-[150px] rounded-[50%] bg-gray-200 overflow-hidden profileSection">
                  {croppedImage ? (
                    <img
                      src={croppedImage}
                      alt="Profile"
                      className="object-cover w-full h-full imageUpload"
                    />
                  ) : image ? (
                    <img
                      src={image}
                      alt="Profile"
                      className="object-cover w-full h-full imageUpload"
                    />
                  ) : (
                    <img
                      src="/images/profile.svg"
                      className="object-cover w-full h-full imageUpload"
                      alt="Default Profile"
                    />
                  )}
                </div>

                <div className="border border-[#EAECF0] uploadingFile cursor-pointer w-full rounded-[12px] flex justify-center items-center p-5">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <div className="text-center flex items-center justify-center">
                        <div>
                          <div className="flex justify-center w-full">
                            <img
                              src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(4).svg?updatedAt=1709799572323"
                              alt=""
                              className="h-[45px] w-[45px]"
                            />
                          </div>
                          <p className="text-[#475467]  textuals">
                            <span className="text-[#004EEB] text-[14px] font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop <br />
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Modal
                title="Crop Image"
                open={isCropModalVisible}
                onCancel={() => setIsCropModalVisible(false)}
                footer={null}
                className="cropingModal"
              >
                <div style={{ height: "400px", position: "relative" }}>
                  {image && (
                    <Cropper
                      image={image}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={(_, croppedAreaPixels) =>
                        setCroppedArea(croppedAreaPixels)
                      }
                    />
                  )}
                  <div className="controls">
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoom(parseFloat(e.target.value));
                      }}
                      className="zoom-range"
                    />
                  </div>
                </div>
                <div className="flex justify-end gap-3 mt-4">
                  <Button onClick={() => setIsCropModalVisible(false)}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    style={{ background: "#155eef" }}
                    onClick={handleCropComplete}
                  >
                    Crop Image
                  </Button>
                </div>
              </Modal>

              <div className="border-t w-full mt-6 pageProfile">
                <div className="pt-4 flex gap-3 items-center justify-end">
                  <Button
                    className=" editBtn  h-[44px] bg-[#fff] rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]"
                    onClick={() => navigate("/")}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    disabled={isLoading}
                    className="deleteBtn h-[44px] min-w-[120px] w-auto bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
                  >
                    {isLoading ? (
                      <Spin />
                    ) : (
                      <>{formEdited ? "Save Changes" : "Edit Details"}</>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Profile;
