import React, { useState, useEffect } from "react";
import { Drawer, Spin, Typography } from "antd";
import { StatusBtn } from "../../components/index.ts";
import { useSelector } from "react-redux";
import moment from "moment";

type initialState = {
  isViewOpen: any;
  setIsViewOpen: any;
  isCompany: any;
  data?: any;
  setViewData?: any;
};

const Sidebar = ({
  isViewOpen,
  setIsViewOpen,
  isCompany,
  data,
  setViewData,
}: initialState) => {
  const { Text } = Typography;
  const payouts = useSelector((state: RootState) => state.payouts.payouts);
  const [totalCommission, setTotalCommission] = useState(0);
  const [paidCommission, setPaidCommission] = useState(0);
  const [unpaidCommission, setUnpaidCommission] = useState(0);

  const calculateCommission = (table_id, payouts) => {
    const selectedPayouts = payouts?.filter(
      (payout) => payout?.pipedrive_org_id?.table_id === table_id,
    );

    let total_commission = 0,
      paid_commission = 0,
      unpaid_commission = 0;

    selectedPayouts?.map((payout) => {
      total_commission += +payout?.commission_paid;

      if (payout?.payment_status === "Paid") {
        paid_commission += +payout?.commission_paid;
      } else if (payout?.payment_status === "Unpaid") {
        unpaid_commission += +payout?.commission_paid;
      }
    });
    setTotalCommission(total_commission);
    setPaidCommission(paid_commission);
    setUnpaidCommission(unpaid_commission);
  };

  useEffect(() => {
    calculateCommission(data?.table_id, payouts);
  }, [data, payouts]);
  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => {
          setIsViewOpen(false);
          setViewData();
        }}
        visible={isViewOpen}
        bodyStyle={{ padding: "20px" }}
        className="custom-drawer"
      >
        {data ? (
          <>
            <div className="w-full h-full ">
              <div className="flex justify-between items-center">
                <Text className="text-[#101828] text-[20px] font-semibold headers">
                  {isCompany ? " Company Details " : "Overview"}
                </Text>

                <img
                  src="https://ik.imagekit.io/8extk8sjo/Icon%20(19).svg?updatedAt=1709552489876"
                  alt=""
                  className="w-[15px] cursor-pointer"
                  onClick={() => {
                    setIsViewOpen(false);
                    setViewData();
                  }}
                />
              </div>

              {!isCompany && (
                <div className="flex items-center gap-3 mt-6">
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/building-05.svg?updatedAt=1709552570646"
                    alt=""
                    className="w-[20px]"
                  />
                  <Text className="text-[#98A2B3] text-[14px] font-medium">
                    Company Details
                  </Text>
                </div>
              )}

              <div className="flex flex-wrap items-center gap-y-3 mt-4">
                <div className="grid w-[50%]">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Name:
                  </Text>
                  <Text className="text-[#475467] text-[14px] font-normal">
                    {data?.Name
                      ? data?.Name
                      : data?.pipedrive_org_id?.Name
                        ? data?.pipedrive_org_id?.Name
                        : "---"}
                  </Text>
                </div>

                <div className="grid w-[50%]">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Domain:
                  </Text>
                  <Text className="text-[#475467] text-[14px] font-normal">
                    {data?.Website
                      ? data?.Website
                      : data?.pipedrive_org_id?.Website
                        ? data?.pipedrive_org_id?.Website
                        : "No domain"}
                  </Text>
                </div>

                <div className="grid w-[50%]">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Refferred On:
                  </Text>
                  <Text className="text-[#475467] text-[14px] font-normal">
                    {data?.add_time
                      ? moment(data?.add_time).format("DD/MM/YYYY")
                      : data?.pipedrive_org_id?.add_time
                        ? moment(data?.pipedrive_org_id?.add_time).format(
                            "DD/MM/YYYY",
                          )
                        : "0/0/0000"}
                  </Text>
                </div>

                <div className="grid w-[50%]">
                  <Text className="text-[#344054] text-[14px] font-medium">
                    Status:
                  </Text>

                  <div className="mt-1">
                    <StatusBtn
                      item={
                        data?.Status
                          ? data?.Status
                          : data?.pipedrive_org_id?.Status
                            ? data?.pipedrive_org_id?.Status
                            : "No status"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex items-center gap-3 mt-6">
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/Icon%20(20).svg?updatedAt=1709554649235"
                    alt=""
                    className="w-[20px]"
                  />
                  <Text className="text-[#98A2B3] text-[14px] font-medium">
                    Contact Details
                  </Text>
                </div>

                <div className="flex flex-wrap items-center gap-y-3 pb-6 border-b border-[#EAECF0]">
                  <div className="grid w-[50%]">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Name:
                    </Text>
                    <Text className="text-[#475467] text-[14px] font-normal">
                      {data?.persons[0]?.name || "Dummy Name"}
                    </Text>
                  </div>
                  <div className="grid w-[50%]">
                    <Text className="text-[#344054] text-[14px] font-medium">
                      Email:
                    </Text>
                    <Text className="text-[#475467] text-[14px] font-normal">
                      {data?.persons[0]?.email || "dummy@email.com"}
                    </Text>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-3 mt-6">
                <img
                  src="https://ik.imagekit.io/8extk8sjo/Icon%20(21).svg?updatedAt=1709554813294"
                  alt=""
                  className="w-[20px]"
                />
                <Text className="text-[#98A2B3] text-[14px] font-medium">
                  Payout
                </Text>
              </div>

              {isCompany ? (
                <>
                  <div className="flex flex-wrap items-center gap-y-3 mt-4 pb-6">
                    <div className="grid w-[50%]">
                      <Text className="text-[#344054] text-[14px] font-medium">
                        Paid amount:
                      </Text>
                      <Text className="text-[#475467] text-[14px] font-normal">
                        ${paidCommission.toFixed(2)}
                      </Text>
                    </div>

                    <div className="grid w-[50%]">
                      <Text className="text-[#344054] text-[14px] font-medium">
                        Unpaid amount:
                      </Text>
                      <Text className="text-[#475467] text-[14px] font-normal">
                        ${unpaidCommission.toFixed(2)}
                      </Text>
                    </div>
                    <div className="grid w-[50%]">
                      <Text className="text-[#344054] text-[14px] font-medium">
                        Total earnings:
                      </Text>
                      <Text className="text-[#475467] text-[14px] font-normal">
                        ${totalCommission.toFixed(2)}
                      </Text>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-wrap items-center gap-y-3 mt-4 pb-4">
                    <div className="grid w-[50%]">
                      <Text className="text-[#344054] text-[14px] font-medium">
                        Paid amount:
                      </Text>
                      <Text className="text-[#475467] text-[14px] font-normal">
                        ${paidCommission.toFixed(2)}
                      </Text>
                    </div>

                    <div className="grid w-[50%]">
                      <Text className="text-[#344054] text-[14px] font-medium">
                        Unpaid amount:
                      </Text>
                      <Text className="text-[#475467] text-[14px] font-normal">
                        ${unpaidCommission.toFixed(2)}
                      </Text>
                    </div>

                    <div className="grid w-[50%]">
                      <Text className="text-[#344054] text-[14px] font-medium">
                        Total earnings:
                      </Text>
                      <Text className="text-[#475467] text-[14px] font-normal">
                        ${totalCommission.toFixed(2)}
                      </Text>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center items-center h-[100vh] w-full">
              <Spin />
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default Sidebar;
