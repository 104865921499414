import React from "react";
import { Typography } from "antd";

type itemProps = {
  heading: string;
  subHeading: string;
  isShow?: boolean;
};
const Heading = ({ heading, subHeading, isShow }: itemProps) => {
  const { Text } = Typography;
  return (
    <div className="grid headingDashboard">
      <h2 className="text-[#101828] text-[30px] font-semibold">{heading}</h2>
      {!isShow && <h4 className="text-[#475467] text-[16px]">{subHeading}</h4>}
    </div>
  );
};

export default Heading;
