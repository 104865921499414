import React from "react";
import { Button, Col, Input } from "antd";
import { SETTINGS_TABS } from "../../constants/data.ts";
const Tabs = ({ itemIndex, setItemIndex }: any): JSX.Element => {
  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  return (
    <div className=" border-b bg-white">
      <div className="container flex items-center justify-between h-[65px]">
        <div className="flex items-center gap-3 tabsSetting">
          <div style={{ display: "flex" }}>
            {SETTINGS_TABS?.map((item: any, index: number) => (
              <Button
                onClick={() => setItemIndex(index)}
                className={` h-[44px] shadow-none border-none p-[8px, 12px, 8px, 12px] rounded-[6px] font-semibold text-[16px] text-[#101828] flex items-center gap-2  ${
                  itemIndex === index && "bg-[#EFF4FF]"
                }`}
              >
                {item?.label}
                {item?.isCount && (
                  <div className="rounded-[16px] bg-[#F2F4F7] px-2 ">
                    {item?.counts}
                  </div>
                )}
              </Button>
            ))}
          </div>
          <Col className="my-8 searchBoxed">
            <Input
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              // onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search"
              prefix={<SearchIcon />}
              className="w-[400px] h-[44px] placeholder:text-black rounded-[8px]"
            />
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
