import React, { useState } from "react";
import { Row, Col, Typography } from "antd";
import Profile from "./profile.tsx";
import PasswordSection from "./password-section.tsx";
import Tabs from "./tabs.tsx";

const Main = () => {
  const { Text } = Typography;
  const [itemIndex, setItemIndex] = useState(0);

  return (
    <div className=" pb-20" style={{ paddingTop: "70px" }}>
      <Tabs setItemIndex={setItemIndex} itemIndex={itemIndex} />

      <div className="container py-12">
        <Row>
          <Col span={6}>
            <div className="grid leftSides">
              <h5 className="text-[#101828] text-[18px] font-semibold">
                {itemIndex === 0
                  ? "Profile"
                  : itemIndex === 1
                    ? "Password"
                    : null}
              </h5>
              <h6 className="text-[#475467] text-[14px] pr-2">
                {itemIndex === 0
                  ? " Update your photo and personal details here."
                  : itemIndex === 1
                    ? "Change your password by filling the details"
                    : null}
              </h6>
            </div>
          </Col>
          <Col span={18}>
            <div
              style={{
                boxShadow:
                  "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
              }}
              className="w-full p-5  rounded-[12px] bg-white rightSides"
            >
              {itemIndex === 0 ? (
                <Profile />
              ) : itemIndex === 1 ? (
                <PasswordSection />
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Main;
